module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"extensions":[".md",".mdx"],"gatsbyRemarkPlugins":[{"resolve":"/opt/build/repo/plugins/gatsby-local-plugin/index.js"},{"resolve":"gatsby-remark-images","options":{"maxWidth":800,"linkImagesToOriginal":false,"showCaptions":["title"],"backgroundColor":"transparent","quality":80,"withWebp":true,"tracedSVG":true}}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-NM4F3MJ","includeInDevelopment":true,"defaultDataLayer":{"type":"object","value":{"platform":"gatsby","env":"master"}}},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"display":"standalone","theme_color":"#E62B1E","background_color":"#000000","icon":"src/images/icon.png","include_favicon":false,"cache_busting_mode":"query","legacy":true,"theme_color_in_head":true,"cacheDigest":"943c286c0929a918a7d7af8ac57c446b"},
    },{
      plugin: require('../node_modules/gatsby-plugin-netlify-cms/gatsby-browser.js'),
      options: {"plugins":[],"modulePath":"/opt/build/repo/src/cms/cms.js","manualInit":true,"enableIdentityWidget":false,"htmlTitle":"TED Masterclass | Netlify CMS"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
